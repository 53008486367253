import React from "react";
import { Link, graphql } from "gatsby";
import Img from "gatsby-image";
import { GatsbySeo } from "gatsby-plugin-next-seo";

interface IImageUrls {
  [index: string]: string;
}

interface IImage {
  urls: IImageUrls;
}

interface IProps {
  images: Array<IImage>;
}

const IndexPage: React.FunctionComponent<IProps> = ({ data }: any) => {
  return (
    <>
      <GatsbySeo
        title="Casa Fraine"
        description="L'idea e' quella di vivere nella maniera piu' eco sostenibile possibile nella speranza di una qualche evoluzione e/o creazione di un prodotto unico"
        canonical="https://www.casafraine.com"
        openGraph={{
          url: "https://www.casafraine.com",
          title: "Casa Fraine",
          description:
            "L'idea e' quella di vivere nella maniera piu' eco sostenibile possibile nella speranza di una qualche evoluzione e/o creazione di un prodotto unico",

          images: [
            {
              url: "https://www.casafraine.com/mapitaly.png",
              width: 800,
              height: 600,
              alt: "Botanic Api",
            },
            {
              url: "https://www.casafraine.com/mapitaly.png",
              width: 900,
              height: 800,
              alt: "Botanic Api",
            },
            { url: "https://www.casafraine.com/mapitaly.png" },
            { url: "https://www.casafraine.com/formaggiodicapra.jpg" },
          ],
          site_name: "Casa Fraine",
        }}
        twitter={{
          handle: "@handle",
          site: "@site",
          cardType: "summary_large_image",
        }}
      />
      <div className="home">
        <section className="hero">
          <h2>
            <span>Casa</span>&nbsp;Fraine
          </h2>
          <Img
            className="img-hero"
            fluid={data.allFile.nodes[4].childImageSharp.fluid}
            alt="verso uno sviluppo sostenibile"
          />
        </section>

        <section className="insieme">
          <center>
            <h2>Insieme possiamo cambiare il modo in cui mangiamo</h2>
          </center>
          <div className="page-home grid-container">
            <div className="grid-item">
              <Img
                className="img"
                fluid={data.allFile.nodes[18].childImageSharp.fluid}
                alt="pumpkins on the ground"
              />
              <Link to="/blog/8-benefici-del-mangiare-cibo-locale">
                <span>L'importanza del cibo locale</span>
              </Link>
            </div>
            <div className="grid-item">
              <Img
                className="img"
                fluid={data.allFile.nodes[16].childImageSharp.fluid}
                alt="girl eating an apple"
              />
              <Link to="#">
                <span>Diminuiamo lo spreco</span>
              </Link>
            </div>
            <div className="grid-item">
              <Img
                className="img"
                fluid={data.allFile.nodes[7].childImageSharp.fluid}
                alt="hand picking up fruits"
              />
              <Link to="/map">
                <span>Scopri gli alberi abbandonati nella tua zona</span>
              </Link>
            </div>
          </div>
        </section>

        <section className="showcase">
          <Img
            className="img__showcase"
            fluid={data.allFile.nodes[15].childImageSharp.fluid}
            alt="mercato locale"
          />
          <div className="showcase__description">
            <h3>Sostieni la vendita di prodotti locali</h3>
            <p>
              Gli studi dimostrano che quando acquisti da un'azienda
              indipendente di proprietà locale, piuttosto che da un'azienda di
              proprietà nazionale, una parte significativamente maggiore del tuo
              denaro viene utilizzata per effettuare acquisti da altre aziende
              locali, fornitori di servizi e aziende agricole, rafforzando la
              base economica di tutta la comunità locale .
            </p>
            <Link className="red" to="/map">
              Leggi di più...
            </Link>
          </div>
        </section>

        <section className="showcase ">
          <Img
            className="img__showcase"
            fluid={data.allFile.nodes[5].childImageSharp.fluid}
            alt="mercato locale cipolle"
            style={{ marginLeft: "auto" }}
          />
          <div className="showcase__description reverse">
            <h3>100% Prodotti Organici</h3>
            <p>
              Nel rispetto della natura, coltiviamo materie prime di alta
              qualità e le valorizziamo portando sulla tavola sapori
              mediterranei, freschi e genuini. Casa Fraine ha adottato una
              politica di trasparenza e ha fatto suo il motto “fedele alla
              natura” che è una filosofia che esalta l’apprezzamento, la fiducia
              e il rispetto di madre natura.
            </p>
            <Link className="green" to="#">
              Leggi di più...
            </Link>
          </div>
        </section>

        <section className="showcase ">
          <Img
            className="img__showcase"
            fluid={data.allFile.nodes[19].childImageSharp.fluid}
            alt="mercato locale tavolo con cibo"
          />

          <div className="showcase__description ">
            <h3>I benefici del cibo a km 0</h3>
            <p>
              La nostra filiera corta, adattata alle esigenze dei clienti del
              nuovo millennio, collega facilmente i produttori locali con i
              consumatori moderni che considerano il tempo una risorsa, la
              “pausa” un momento per ricaricare sia il corpo che la mente, per
              chi vuole vivere la natura ma ama le comodità di casa.
            </p>
            <Link className="red" to="#">
              Leggi di più...
            </Link>
          </div>
        </section>
      </div>
    </>
  );
};

export const query = graphql`
  query indexQuery {
    allFile(
      filter: { sourceInstanceName: { eq: "images" } }
      sort: { fields: base }
    ) {
      nodes {
        name
        absolutePath
        childImageSharp {
          fluid {
            tracedSVG
            srcWebp
            srcSetWebp
            originalImg
            originalName
          }
        }
      }
    }
  }
`;
export default IndexPage;
